import React, {useCallback} from "react";
import IconMenuItem from "../../../../components/common/IconMenuItem";
import {IconSupport} from "../../../../bounce-ui/consumer";
import NiceModal from "@ebay/nice-modal-react";
import ContactUsDialog from "../../../../pages/contact-us/ContactUsDialog";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";

interface ISupportItem {
  closeHandler: () => void;
}

const SupportItem = ({closeHandler}: ISupportItem) => {
  const {pageClickEvent} = useAmplitude();

  const handleSupport = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "get_support"});
    closeHandler();
    NiceModal.show(ContactUsDialog, {});
  }, []);

  return <IconMenuItem labelId="getHelpFromSupport" icon={<IconSupport />} onClick={handleSupport} />;
};

export default SupportItem;
