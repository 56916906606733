import React, {useCallback} from "react";
import IconMenuItem from "../../../../../components/common/IconMenuItem";
import NiceModal from "@ebay/nice-modal-react";
import {IconPaymentMethod} from "../../../../../bounce-ui/consumer";
import {OneTimePaymentDialog} from "../../dialogs";
import {useAmplitude} from "../../../../../contexts";
import {Pages} from "../../../../../bi-events/enum";

interface IOneTimePaymentItem {
  accountId: string;
  planBalance: number;
  activePaymentPlanId?: string;
  closeHandler: () => void;
}

const OneTimePaymentItem = ({accountId, planBalance, closeHandler, activePaymentPlanId}: IOneTimePaymentItem) => {
  const {pageClickEvent} = useAmplitude();

  const payNowHandler = useCallback(async (): Promise<void> => {
    pageClickEvent({page: Pages.MENU, cta: "one_time_payment"});
    closeHandler();
    NiceModal.show<boolean>(OneTimePaymentDialog, {
      planBalance,
      accountId: accountId,
      activePaymentPlanId,
    });
  }, [accountId]);

  return <IconMenuItem labelId="oneTimePayment" icon={<IconPaymentMethod />} onClick={payNowHandler} />;
};

export default OneTimePaymentItem;
