import {Colors, Container, Text} from "../../../bounce-ui/consumer";
import {Link, IconButton} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {useAmplitude} from "../../../contexts";
import {Pages} from "../../../bi-events/enum";

interface IChatTooltipLabel {
  handleClick: () => void;
  onClose: () => void;
}
export const ChatTooltipLabel = ({handleClick, onClose}: IChatTooltipLabel) => {
  const {pageClickEvent} = useAmplitude();
  const theme = useTheme();

  const handleCloseChat = () => {
    pageClickEvent({page: Pages.CHAT_BANNER, cta: "close"});
    onClose();
  };

  return (
    <Container
      sx={{
        alignItems: "start",
        padding: "10px 12px",
        borderRadius: "4px",
        marginRight: "20px",
        backgroundColor: Colors.bw.white,
      }}
    >
      <IconButton
        aria-label="close"
        size="small"
        onClick={handleCloseChat}
        sx={{
          position: "absolute",
          top: {xs: theme.spacing(0.2), sm: theme.spacing(0.2)},
          right: {xs: theme.spacing(0.2), sm: theme.spacing(0.2)},
          color: "#B6B8C4",
          zIndex: 10,
        }}
      >
        <CloseOutlined />
      </IconButton>
      <Text fontWeight={"bold"} size={14} translateId={"needSomeHelp"}>
        <Text size={14} translateId={"reachOutToOneOfOurResolutionExperts"}>
          <Link onClick={handleClick} sx={{textDecoration: "none"}}>
            <Text
              size={14}
              fontWeight={"bold"}
              translateId={"chatNow"}
              sx={{color: "secondary", display: "inline", cursor: "pointer"}}
            />
          </Link>
        </Text>
      </Text>
    </Container>
  );
};
