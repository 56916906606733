import {Chip} from "@mui/material";
import {Colors, IconStar, Text} from "../../../../bounce-ui/consumer";

const PopularBadge = (
  <Chip
    icon={<IconStar />}
    label={
      <Text
        translateId={"popular"}
        variant={"body2"}
        size={12}
        sx={{textTransform: "none", color: Colors.primary._800}}
      />
    }
    sx={{
      backgroundColor: (theme) => theme.palette.success.lighter,
      width: "76px",
      height: "22px",
      borderRadius: "16px",
      gap: "4px",
    }}
  />
);
export default PopularBadge;
