import React, {useCallback, useMemo, useRef, useState} from "react";
import {Drawer, IconButton} from "@mui/material";
import {useAmplitude, useUser} from "../../../contexts";
import {useGetAccount} from "apis/requests/user/getAccount";
import {Container, CloseDialogButton, LabeledText, IconMenu, Colors} from "bounce-ui/consumer";
import DashboardMenuItems from "./DashboardMenuItems";
import {DisputeItem, HomeItem, LogoutItem, ManageContactItem, SupportItem} from "./menu-items";
import {Pages} from "../../../bi-events/enum";
import {useAccountId} from "../../../contexts/useAccountId";

const SectionTitle = ({title}: {title: string}) => (
  <LabeledText
    translateId={title}
    size={12}
    fontWeight={"medium"}
    sx={{
      color: (theme) => theme.palette.text.primary,
      backgroundColor: Colors.grey._100,
      width: "100%",
      textTransform: "uppercase",
      padding: "2px 52px",
    }}
  />
);

const MainMenu = () => {
  const {pageViewEvent, pageClickEvent} = useAmplitude();
  const {loggedIn, userId, clearCredentials, isAgent} = useUser();
  const accountId = useAccountId();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const {data: account} = useGetAccount({accountId}, {enabled: !!accountId});
  const activePaymentPlan = useMemo(() => {
    if (account) return account.active_payment_plan;
  }, [account]);
  const isAccountResolved = activePaymentPlan?.state === "resolved";
  const handleOpen = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "open_menu"});
    pageViewEvent({page: Pages.MENU});
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  if (!loggedIn) {
    return null;
  }
  return (
    <>
      <IconButton sx={{color: "white"}} ref={anchorRef} onClick={handleOpen}>
        <IconMenu />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose} sx={{width: 220}}>
        <div role="presentation" onClick={handleClose} onKeyDown={handleClose}>
          <CloseDialogButton onClose={handleClose} isDrawer />
          <Container width={"360px"} sx={{padding: "58px 0px", gap: "24px"}}>
            <Container fullWidth sx={{gap: "0px"}}>
              <SectionTitle title={"general"} />
              <HomeItem closeHandler={handleClose} />
            </Container>
            {activePaymentPlan && !isAccountResolved && accountId && (
              <Container fullWidth sx={{gap: "0px"}}>
                <SectionTitle title={"paymentPreferences"} />
                <DashboardMenuItems
                  accountId={accountId}
                  activePaymentPlanId={activePaymentPlan.payment_plan_id}
                  closeHandler={handleClose}
                />
              </Container>
            )}
            <Container fullWidth sx={{gap: "0px"}}>
              <SectionTitle title={"generalOptions"} />
              {userId && <ManageContactItem userId={userId} closeHandler={handleClose} />}
              <SupportItem closeHandler={handleClose} />
              {!activePaymentPlan && accountId && <DisputeItem accountId={accountId} closeHandler={handleClose} />}
              {!isAgent && userId && (
                <LogoutItem userId={userId} closeHandler={handleClose} clearCredentials={clearCredentials} />
              )}
            </Container>
          </Container>
        </div>
      </Drawer>
    </>
  );
};

export default MainMenu;
