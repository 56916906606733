import {Colors, Container, Text} from "../../../bounce-ui/consumer";
import {IconButton, Link} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {useAmplitude} from "../../../contexts";
import {Pages} from "../../../bi-events/enum";
import {useGetUserQuery} from "../../../apis/requests/user/getUser";
import {useWebchat} from "./useWebchat";

interface IChatPlansTooltipLabel {
  onClose: () => void;
}
export const ChatPlansTooltipLabel = ({onClose}: IChatPlansTooltipLabel) => {
  const {pageClickEvent} = useAmplitude();
  const theme = useTheme();
  const {data: user} = useGetUserQuery({});

  const {openChat} = useWebchat();

  const handleOpenChat = () => {
    pageClickEvent({page: Pages.HARDSHIP_PLAN_DIALOG, cta: "continue"});
    onClose();
    openChat({
      data: {
        email: user?.email,
        name: user?.name,
        query: "I'd like to learn more about the hardship program",
      },
    });
  };

  const handleCloseChat = () => {
    pageClickEvent({page: Pages.CHAT_BANNER, cta: "close"});
    onClose();
  };

  return (
    <Container
      sx={{
        alignItems: "start",
        padding: "2px 4px",
        borderRadius: "4px",
        marginRight: "20px",
        gap: "2px",
      }}
    >
      <IconButton
        aria-label="close"
        size="small"
        onClick={handleCloseChat}
        sx={{
          position: "absolute",
          top: {xs: theme.spacing(0.2), sm: theme.spacing(0.2)},
          right: {xs: theme.spacing(0.2), sm: theme.spacing(0.2)},
          color: "#B6B8C4",
          zIndex: 10,
        }}
      >
        <CloseOutlined />
      </IconButton>
      <Text fontWeight={"bold"} size={14} translateId={"cantAffordAnyOfThesePlans"} sx={{color: Colors.bw.white}} />
      <Link onClick={handleOpenChat}>
        <Text
          size={14}
          fontWeight={"bold"}
          translateId={"chatWithUsNow"}
          sx={{cursor: "pointer", color: Colors.bw.white, textDecoration: "underline"}}
        />
      </Link>
    </Container>
  );
};
