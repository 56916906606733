import {PaymentPlanOffer, PaymentPlanOffers} from "../../../../declerations/server/payment_plan_models";
import {sortBy} from "lodash";
import {Container, IconCheckbox, Text} from "../../../../bounce-ui/consumer";
import {ReactNode} from "react";

export type PlanType = "one-time" | "installments";

const sortOptions = (monthlyPlanOffers: PaymentPlanOffer[]) => {
  return sortBy(monthlyPlanOffers, "number_of_installments");
};
const getOptions = (monthlyPlanOffers: PaymentPlanOffer[]) => {
  const numberOfInstallments = monthlyPlanOffers.length;
  const sortedOptions = sortOptions(monthlyPlanOffers);
  if (numberOfInstallments > 2) {
    return [...sortedOptions.slice(0, 2), sortedOptions[numberOfInstallments - 1]];
  }
  if (numberOfInstallments === 0) {
    return [];
  }
  return sortedOptions;
};

const Label = ({label, children}: {label: string; children?: ReactNode}) => (
  <Container row sx={{justifyContent: "center", height: "40px"}}>
    <Text variant={"body2"} size={14}>
      {label}
    </Text>
    {children}
  </Container>
);

const CheckboxLabel = ({label}: {label: string}) => (
  <Container row sx={{gap: "4px"}}>
    <IconCheckbox />
    <Text variant={"body2"} size={12}>
      {label}
    </Text>
  </Container>
);
const getHasPlanOptions = ({planOffers}: {planOffers: PaymentPlanOffers}) => {
  const plans = Object.keys(planOffers)
    .filter((key) => !["one_time", "recommended", "payoff"].includes(key))
    .reduce((reduced_plans: Record<string, any>, key) => {
      reduced_plans[key] = planOffers[key as keyof PaymentPlanOffers];
      return reduced_plans;
    }, {} as PaymentPlanOffers);
  return Object.values(plans).some((value) => value.length > 0);
};

export {getOptions, Label, CheckboxLabel, getHasPlanOptions};
