import React from "react";
import {Colors, Container, LabeledText, Text} from "../../../../bounce-ui/consumer";

const LargePaymentSplitLabel = () => {
  return (
    <LabeledText sx={{backgroundColor: Colors.grey._100}}>
      <Container sx={{alignItems: "start", padding: "16px 20px"}}>
        <Text translateId={"largePaymentSplitTitle"} fontWeight={"bold"} />
        <Text translateId={"largePaymentSplitMessage"} />
      </Container>
    </LabeledText>
  );
};
export default LargePaymentSplitLabel;
