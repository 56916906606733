import React, {useEffect} from "react";
import {Container, IconEditGreen, Divider, Colors} from "../../../../../../bounce-ui/consumer";
import dayjs, {Dayjs} from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {mapFrequencyToTitle} from "../../../../../account/payment-plans-v2/utils";
import {DATE_FORMAT_V2} from "../../../components/dialogs/common";
import {SummaryText} from "../../../../../account/payment-plans-v2/PaymentPlanComponents/utils";
import {AllPlanFrequencies} from "../../../../../../declerations/enums";
import {useAmplitude} from "../../../../../../contexts";
import {Pages} from "../../../../../../bi-events/enum";
import {isUndefined} from "lodash";
import {ICustomizedInstallment} from "./custom-view/useCustomView";
import {prettifyCurrency, subtract} from "helpers/currency";
dayjs.extend(utc);
dayjs.extend(timezone);

interface IInstallmentsSummaryDetails {
  last_4_digits?: string;
  payment_method_type?: string;
  discount?: number;
  endDate?: string;
  totalBalance?: number;
  totalPlanAmount: number;
  frequency: AllPlanFrequencies;
  installmentSize: number;
  lastPayment: number;
  numOfInstallments: number;
  startDate: Dayjs;
  onClickEdit?: () => void;
  currentEndDate: string;
  customInstallment?: ICustomizedInstallment;
  isHardship?: boolean;
}

const InstallmentsSummary = ({
  last_4_digits,
  payment_method_type,
  discount,
  lastPayment,
  numOfInstallments,
  totalBalance,
  totalPlanAmount,
  frequency,
  installmentSize,
  startDate,
  onClickEdit,
  currentEndDate,
  isHardship,
}: IInstallmentsSummaryDetails) => {
  const {pageViewEvent, pageClickEvent} = useAmplitude();
  const isCustomPlan = frequency === "non_linear";

  const onClickDate = () => {
    if (onClickEdit) {
      pageClickEvent({page: Pages.PLAN_SUMMARY, cta: "change_date"});
      onClickEdit();
    }
  };

  useEffect(() => {
    pageViewEvent({page: Pages.PLAN_SUMMARY});
  }, []);
  const amountDeducted = subtract(totalBalance || 0, totalPlanAmount);
  return (
    <Container fullWidth sx={{gap: "3px"}}>
      {last_4_digits && payment_method_type && <SummaryText field={payment_method_type} value={last_4_digits} />}
      {!isUndefined(discount) && discount > 0 && <SummaryText field={"Discount"} value={`${discount * 100}%`} />}
      {amountDeducted > 0 && totalBalance && (
        <SummaryText field={"Total balance due"} value={prettifyCurrency(totalBalance)} lineCross />
      )}
      <SummaryText field={"Chosen plan amount"} value={prettifyCurrency(totalPlanAmount)} />
      {amountDeducted > 0 && totalBalance && (
        <SummaryText field={"Amount deducted"} value={prettifyCurrency(totalBalance - totalPlanAmount)} />
      )}
      <Divider sx={{width: "100%", height: "2px", borderColor: Colors.grey._100, margin: "5px 0px"}} />
      <SummaryText
        field={"Installments"}
        value={
          !isCustomPlan && installmentSize && !isHardship
            ? `${String(numOfInstallments)} X ${prettifyCurrency(installmentSize)}`
            : "Custom"
        }
      />
      {!!lastPayment && !isCustomPlan && (
        <SummaryText field={"Last installment"} value={prettifyCurrency(lastPayment)} />
      )}
      <SummaryText field={"Frequency"} value={isCustomPlan ? "Custom" : mapFrequencyToTitle[frequency].title} />
      <Divider sx={{width: "100%", height: "2px", borderColor: Colors.grey._100, margin: "5px 0px"}} />
      {onClickEdit ? (
        <SummaryText
          field={"Start date"}
          value={dayjs(startDate).format(DATE_FORMAT_V2)}
          icon={<IconEditGreen />}
          onClickIcon={onClickDate}
        />
      ) : (
        <SummaryText field={"Start date"} value={dayjs(startDate).format(DATE_FORMAT_V2)} />
      )}
      <SummaryText field={"End date"} value={dayjs(currentEndDate).format(DATE_FORMAT_V2)} />
    </Container>
  );
};

export default InstallmentsSummary;
