import React, {FunctionComponent, useEffect} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Container, Text, Dialog, TextField} from "../../bounce-ui/consumer";
import {useAmplitude, useTask, useUser} from "../../contexts";
import {Pages} from "../../bi-events/enum";
import {Controller, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {useSnackbar} from "notistack";
import {useAddEvent} from "../../apis/requests/events/addEvent";
import {useGetContactDetailQuery} from "../../apis/requests/user/getConatctDetails";
import {EMAIL_REGEX} from "../../constants/regex";

interface ContactUsDialogProps extends NiceModalHocProps {
  fetchContact: boolean;
}

type FormValues = {
  email: string;
  message: string;
};

const ContactUsDialog: FunctionComponent<ContactUsDialogProps> = NiceModal.create(({fetchContact = true, ...props}) => {
  const {pageViewEvent} = useAmplitude();
  const {pageClickEvent} = useAmplitude();
  const {control, handleSubmit, formState, reset, setValue} = useForm<FormValues>();
  const {isValid} = formState;
  const {isAgent} = useUser();
  const {getUserId} = useTask();
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();
  const addEventState = useAddEvent();
  const contactDetail = useGetContactDetailQuery({}, {enabled: fetchContact});

  useEffect(() => {
    if (contactDetail?.data) {
      setValue("email", contactDetail.data.email);
    }
  }, [contactDetail?.data?.email]);

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    try {
      const result = await addEventState.mutateAsync({
        eventData: {
          type: "CONTACT_US",
          user_id: isAgent ? getUserId() : undefined,
          account_id: undefined,
          data: {email: data.email, text: data.message},
        },
        isAgent: isAgent,
      });
      if (result) {
        enqueueSnackbar(<Text translateId={"messageSentSuccessfully"} size={14} />, {variant: "success"});
        reset();
      }
    } catch (e) {
    } finally {
      pageClickEvent({page: Pages.GET_HELP, cta: "send", data: {text: data.message}});
    }
  });

  useEffect(() => {
    pageViewEvent({page: Pages.GET_HELP});
  }, []);

  return (
    <Dialog title={"How can we help?"} buttonText={"Send"} onClick={onSubmit} disabled={!isValid}>
      <Text translateId={"sendUsMessage"} />
      <form style={{width: "100%"}}>
        <Container sx={{gap: "8px", alignItems: "start", height: "300px"}}>
          <Controller
            name="email"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
              pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
            }}
            control={control}
            defaultValue=""
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"Email"}
                  type="email"
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              );
            }}
          />
          <Controller
            name="message"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            defaultValue={""}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  placeholder={"Type Your Message Here"}
                  inputRef={ref}
                  variant={"outlined"}
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  multiline
                  rows={9}
                  autoComplete="off"
                  error={invalid}
                  helperText={error?.message}
                  InputLabelProps={{required: false}}
                  inputProps={{
                    maxLength: 1024,
                  }}
                  sx={{margin: "0px"}}
                />
              );
            }}
          />
        </Container>
      </form>
    </Dialog>
  );
});

export default ContactUsDialog;
