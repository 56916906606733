import React, {useCallback} from "react";
import IconMenuItem from "../../../../components/common/IconMenuItem";
import {IconLogout} from "../../../../bounce-ui/consumer";
import {useNavigate, createSearchParams} from "react-router-dom";
import {urls} from "../../../../routes/urls";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";

interface ILogoutItem {
  userId: string;
  closeHandler: () => void;
  clearCredentials: () => void;
}

const LogoutItem = ({userId, closeHandler, clearCredentials}: ILogoutItem) => {
  const {pageClickEvent} = useAmplitude();
  const navigate = useNavigate();

  const handleLogout = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "logout"});
    closeHandler();

    clearCredentials();
    if (userId) {
      navigate({
        pathname: urls.login.url,
        search: createSearchParams({
          id: userId,
        }).toString(),
      });
    } else {
      navigate("/");
    }
  }, [closeHandler, userId, clearCredentials]);

  return <IconMenuItem labelId="logout" icon={<IconLogout />} onClick={handleLogout} />;
};

export default LogoutItem;
