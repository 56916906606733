import React, {useCallback} from "react";
import IconMenuItem from "../../../../../components/common/IconMenuItem";
import NiceModal from "@ebay/nice-modal-react";
import {IconCancelAutoPay} from "../../../../../bounce-ui/consumer";
import AreYouSureDialog from "../../../../../pages/backoffice/information-panel/components/dialogs/AreYouSureDialog";
import {useCancelPaymentPlanAutoPay} from "../../../../../apis/requests/backoffice/cancelPaymentPlanAutoPay";
import {useSnackbar} from "notistack";
import {Text} from "bounce-ui/consumer";

interface ICancelMyPlanItem {
  accountId: string;
  paymentPlanId: string;
  closeHandler: () => void;
}

const CancelAutoPayItem = ({accountId, paymentPlanId, closeHandler}: ICancelMyPlanItem) => {
  const {enqueueSnackbar} = useSnackbar();
  const cancelAutoPay = useCancelPaymentPlanAutoPay({});

  const handleCancelAutoPay = useCallback(async (): Promise<void> => {
    const result = await cancelAutoPay.mutateAsync({
      accountId,
      paymentPlanId,
    });
    if (result) {
      enqueueSnackbar(<Text translateId={"autoPayIsCanceledSuccessfully"} size={14} />, {variant: "success"});
    }
  }, [accountId]);

  const openAreYouSureDialog = () => {
    closeHandler();
    NiceModal.show(AreYouSureDialog, {
      onConfirm: handleCancelAutoPay,
      contentTextId: "Are you sure you want to cancel auto pay?",
      confirmTextId: "Continue",
    });
  };

  return <IconMenuItem labelId="cancelAutoPay" icon={<IconCancelAutoPay />} onClick={openAreYouSureDialog} />;
};

export default CancelAutoPayItem;
