import {Container, IconDocument, Text, IconAttachment, Colors} from "../../../bounce-ui/consumer";
import React, {useCallback} from "react";
import {Link} from "@mui/material";
import {FileRead} from "../../../declerations/server/files_models";
import {FileTypeMap} from "../../../constants/enums";
import {useUser} from "../../../contexts";
import {downloadUserFiles} from "../../../apis/requests/files";
import inputUtils from "../../../helpers/InputUtils";
import useIsMobile from "../../../helpers/useIsMobile";

interface IDocumentDetails {
  file: FileRead;
  accountId?: string;
  isAttachment?: boolean;
  isTable?: boolean;
}

const DocumentDetails = ({file, accountId, isAttachment, isTable}: IDocumentDetails) => {
  const {isAgent} = useUser();
  const isMobile = useIsMobile();

  const handleClick = useCallback(() => {
    downloadUserFiles({accountId, fileId: file.id, isAgent, isMobile, viewMode: true, fileName: file.filename});
  }, [accountId]);

  const truncate = !inputUtils.containsHyphenOrSpace(file.filename);
  return (
    <Container fullWidth row sx={{alignItems: "start"}}>
      {isAttachment ? <IconAttachment /> : <IconDocument />}
      <Link onClick={handleClick} sx={{textDecoration: "none"}}>
        <Container sx={{alignItems: "start", gap: "0px", flexDirection: isAttachment ? "row" : "column"}}>
          <Text
            size={14}
            variant={"body2"}
            sx={{
              whiteSpace: "break-spaces",
              cursor: "pointer",
              ...(isTable && {textDecoration: "underline", color: Colors.primary._700}),
              ...(truncate && isMobile && {width: "250px", overflow: "hidden", textOverflow: "ellipsis"}),
            }}
          >
            {file.filename}
          </Text>
          {!isTable && (
            <Container row sx={{alignItems: "start"}}>
              <Text size={14}>{FileTypeMap.get(file.type)?.labelKey}</Text>
              <Text
                size={14}
                fontWeight={"bold"}
                sx={{
                  display: "inline",
                  cursor: "pointer",
                  color: Colors.primary._700,
                }}
              >
                View
              </Text>
            </Container>
          )}
        </Container>
      </Link>
    </Container>
  );
};

export default DocumentDetails;
