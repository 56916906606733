import React, {useCallback} from "react";
import IconMenuItem from "../../../../../components/common/IconMenuItem";
import NiceModal from "@ebay/nice-modal-react";
import {IconNoEntry} from "../../../../../bounce-ui/consumer";
import {PreCancelPlanDialog, DelayPaymentDialogResult} from "../../dialogs";
import {useAmplitude} from "../../../../../contexts";
import {Pages} from "../../../../../bi-events/enum";

interface ICancelMyPlanItem {
  accountId: string;
  frequency: string;
  activePaymentPlanId: string;
  closeHandler: () => void;
}

const CancelMyPlanItem = ({accountId, frequency, activePaymentPlanId, closeHandler}: ICancelMyPlanItem) => {
  const {pageClickEvent} = useAmplitude();

  const openPreCancelPlanDialog = useCallback(async (): Promise<void> => {
    pageClickEvent({page: Pages.MENU, cta: "cancel_plan"});
    closeHandler();
    await NiceModal.show<DelayPaymentDialogResult>(PreCancelPlanDialog, {
      accountId: accountId,
      frequency,
      activePaymentPlanId,
      onClose: closeHandler,
    });
  }, [closeHandler, accountId]);

  return <IconMenuItem labelId="cancelMyPlan" icon={<IconNoEntry />} onClick={openPreCancelPlanDialog} />;
};

export default CancelMyPlanItem;
